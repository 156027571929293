import { httpClient } from '@/httpClient';
import _ from 'lodash';
import { isValidDomain } from '@/helpers/urlHelpers';
import config from '@/config'; // eslint-disable-line import/extensions

export default {
  namespaced: true,
  state: {
    isAuth: false,
    currentUser: {
      roles: [{}],
    },
    returnUrl: null,
  },
  getters: {
    currentUserHasRoles: (state) => (...roleIds) => {
      const userRoleIds = _.map(state.currentUser.roles, 'id');
      const intersection = _.intersection(userRoleIds, roleIds);
      return intersection.length > 0;
    },
    currentUserHasRolesWithNames: (state) => (...roleNames) => {
      const userRoleNames = _.map(state.currentUser.roles, 'name');
      const intersection = _.intersection(userRoleNames, roleNames);
      return intersection.length > 0;
    },
  },
  mutations: {
    login(state) {
      state.isAuth = true;
    },
    logout(state) {
      state.isAuth = false;
      localStorage.removeItem('auth-token');
      delete httpClient.defaults.headers.common.Authorization;
    },
    setCurrentUser(state, data) {
      state.currentUser = data;
    },
    setReturnUrl(state, url) {
      state.returnUrl = url;
    },
  },
  actions: {
    getCurrentUser(store) {
      return httpClient.get('/api/auth').then((resp) => {
        const { data } = resp;
        store.commit('setCurrentUser', data);
        return data;
      });
    },
    saveReturnUrl({ commit }, url) {
      commit('setReturnUrl', url);
    },
    login(store, { email, password }) {
      return httpClient.post('/api/auth/login', { email, password }, {
        withCredentials: true,
      })
        .then((resp) => {
          const { token } = resp.data;
          store.commit('login');
          localStorage.setItem('auth-token', token);
          httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;

          return store.dispatch('getCurrentUser').then(() => {
            const { returnUrl } = store.state;

            if (returnUrl && isValidDomain(returnUrl, config.baseDomain)) {
              window.location.href = returnUrl;
            }
          });
        });
    },

    forgotPassword(store, { email }) {
      return httpClient.post('/api/auth/forgot_password', { email }).then(({ data }) => data);
    },
    resetPassword(store, { email, token }) {
      return httpClient.get(`/api/auth/reset_password?email=${email}&token=${token}`).then(({ data }) => data);
    },
  },
};
