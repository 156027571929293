import { api } from '@/api';
import { httpClient } from '@/httpClient';
import { numBool } from '@/helpers/numBool';

export default {
  namespaced: true,
  state: {
    items: [],
    isLoading: false,

    current: {
      isLoading: false,
    },
    currentTopViewUrl: null,
    designFiles: [],
    deletedItems: [],
  },
  mutations: {
    setFurniture(state, items) {
      state.items = items;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    setCurrentFurniture(state, data) {
      state.current = {
        ...state.current,
        ...data,
      };
    },
    setCurrentLoading(state, loading) {
      state.current.isLoading = loading;
    },
    setDesignFiles(state, designFiles) {
      state.designFiles = designFiles;
    },
    setDeletedFurniture(state, deletedItems) {
      state.deletedItems = deletedItems;
    },
  },
  actions: {
    getDesignFiles(store) {
      store.commit('setLoading', true);

      return api.getDesignFiles().then(({ data }) => {
        store.commit('setDesignFiles', data);
        store.commit('setLoading', false);
      });
    },

    getList(store) {
      store.commit('setLoading', true);

      const params = { page: store.state.page };

      return api.getFurnitureList(params).then(({ data }) => {
        store.commit('setFurniture', data);
        store.commit('setLoading', false);
      });
    },

    async getFurniture({ commit }, id) {
      commit('setCurrentLoading', true);
      return httpClient.get(`api/furniture/${id}`).then(({ data }) => {
        commit('setCurrentFurniture', data);
        commit('setCurrentLoading', false);
      });
    },

    updateFurnitureParams({ commit }, { id, params }) {
      const fd = new FormData();

      fd.append('x_length', params.xLength);
      fd.append('y_length', params.yLength);
      fd.append('z_length', params.zLength);
      fd.append('z_location', params.zLocation);
      fd.append('is_scalable', numBool(params.isScalable));
      fd.append('is_procedural', numBool(params.isProcedural));
      fd.append('spatial_anchor', params.spatialAnchor);
      fd.append('type', params.type);

      params.styles.forEach((style) => fd.append('styles[]', style));
      params.roomTypes.forEach((type) => fd.append('room_types[]', type));

      if (params.valueMesh) {
        fd.append('mesh', params.valueMesh);
      }

      return httpClient.post(`/api/furniture/${id}`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(({ data }) => {
        commit('setCurrentFurniture', data);
        return data;
      });
    },

    renameMaterial({ commit }, { id, params }) {
      return httpClient.post(`api/furniture/${id}/textures/rename_material`, params).then(({ data }) => {
        commit('setCurrentFurniture', data);
      });
    },

    async updateFurnitureImg({ commit, state }, { id, img, type }) {
      const fd = new FormData();
      fd.append('image', img);
      fd.append('type', type);
      const response = await httpClient.post(`/api/furniture/${id}/update_image`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      commit('setCurrentFurniture', response.data);

      commit('setFurniture', state.items.map((item) => {
        if (item.id === response.data.id) {
          return response.data;
        }

        return item;
      }));

      return true;
    },

    deleteFurnitureTopView(store, id) {
      return api.deleteFurnitureTopView(id).then((resp) => resp);
    },

    deleteFurniturePreview(store, id) {
      return api.deleteFurniturePreview(id).then((resp) => resp);
    },

    deleteFurnitureById(store, id) {
      return api.deleteFurniture(id);
    },

    createDesignFile(store, {
      file, name,
    }) {
      const fd = new FormData();
      fd.append('file_name', name);
      fd.append('file', file);
      return api.createDesignFile(fd).then(() => {
        store.dispatch('getDesignFiles');
      });
    },

    deleteDesignFile(store, id) {
      return api.deleteDesignFile(id).then(() => {
        store.dispatch('getDesignFiles');
      });
    },

    changeDesignFile(store, {
      id, file, name,
    }) {
      const fd = new FormData();
      fd.append('file', file);
      fd.append('file_name', name);
      return api.changeDesignFile(id, fd).then(() => {
        store.dispatch('getDesignFiles');
      });
    },

    downloadDesignFile(store, id) {
      return api.downloadDesignFile(id);
    },

    getDeletedFurnitureList(store) {
      store.commit('setLoading', true);
      return api.getDeletedFurnitureList().then(({ data }) => {
        store.commit('setDeletedFurniture', data);
        store.commit('setLoading', false);
      });
    },

    restoreFurniture(store, id) {
      return api.restoreFurniture(id).then((resp) => {
        store.dispatch('getDeletedFurnitureList');
        return resp;
      });
    },
  },
};
