export const isValidDomain = (url, baseDomain) => {
  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol !== 'https:') {
      return false;
    }
    return parsedUrl.hostname.endsWith(`.${baseDomain}`) || parsedUrl.hostname === baseDomain;
  } catch (e) {
    return false;
  }
};
