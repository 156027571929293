import { ref } from 'vue';
import { Ru } from '../../locale/ru';

const localeStore = () => {
  const currentLocale = ref('ru');
  const locales = {
    ru: Ru,
  };

  const getLocaleText = (key) => locales[currentLocale.value][key] || key;

  return {
    currentLocale,
    getLocaleText,
  };
};

export default localeStore;
