export const Ru = {
  'custom-domain': 'Кастомный домен',
  'telegram-chat-id': 'ID телеграм чата',
  'plan-2d-status': 'Статусы 2D планировок',
  'plan-3d-status': 'Статусы 3D планировок',
  'webhook-token': 'Webhook token',
  'webhook-url': 'Webhook URL',
  webhook: 'Webhook',
  token: 'Token',
  'branding-id': 'ID брендинга',
  'domen-name': 'Доменное имя',
  'company-name': 'Название компании',
  user: 'Пользователь',
  ninja: 'Ниндзя',
  cutter: 'Резчик',
  manager: 'Менеджер',
  branding: 'Брендинг',
  name: 'Имя',
  email: 'Email',
  role: 'Роль',
  number: 'Номер',
  status: 'Статус',
  'status-3d': 'Статус 3D',
  tags: 'Теги',
  tag: 'Тег',
  'apartment-program': 'Квартирограмма',
  priority: 'Приоритет',
  'ue-name': 'Имя в Unreal',
  merged: 'Слита',
  'merged-id': 'Слили с планировкой ID',
  'original-plan': 'Оригинал',
  'original-plan-status': 'Статус оригинала',
  'plan-number-in-apartment': 'Номер планировки',
  identifiers: 'Идентификаторы',
  'individual-style': 'Индивидуальный стиль',
  'has-panorama': 'Виртуальный тур',
  ruler: 'Линейка',
  'interactive-top-view': 'Интерактивный вид сверху',
  'top-view-as-mini-plan': 'Вид сверху в миниплане',
  'projection-mode': 'Тип проекции для топ видов',
  'camera-navigation': 'Тип навигации',
  'hidden-furniture': 'Скрыта необязательная мебель',
  'transparent-background': 'Прозрачный фон',
  'rtx-quality': 'Качество рендера',
  'is-webp-enabled': 'Формат изображения',
  'responsible-ninjas-2d': 'Ответственные 2D',
  'responsible-ninjas-3d': 'Ответственные 3D',
  region: 'Регион',
  developer: 'Застройщик',
  complex: 'ЖК',
  address: 'Адрес',
  'same-ninja-for-2d-and-3d-plans': 'Один ниндзя для 2Д и 3Д',
  description: 'Описание',
  'strategic-data': 'Стратегические данные',
  'camera-point-id': 'Камера Point ID',
  'panorama-fov': 'Panorama Fov',
  'readiness-date': 'Дата готовности',
  'creation-date': 'Дата создания',
  'updating-date': 'Дата обновления',
  'work-time': 'Время работы',
  period: 'Период',
  mesh: 'Модель (FBX)',
  type: 'Тип',
  room: 'Комната',
  material: 'Материал',
  actions: 'Действия',
  'x-length': 'X',
  'y-length': 'Y',
  'z-length': 'Z',
  'z-location': 'Высота по-умолчанию',
  'is-scalable': 'Скалируемая',
  'is-procedural': 'Процедурная',
  environment: 'Окружение',
  'original-plan-id': 'ID Оригинальной планировки',
  additional: 'Дополнительно',
  renderer_version: 'Версия рендера',
  'sky-interior-cubemap-rotation': 'Поворот текстуры неба',
  'sky-interior-skylight-intensity': 'Яркость освещения',
  'sky-interior-skysphere-intensity': 'Яркость текстур',
  'expert-level-2d': 'Уровень 2D',
  'expert-level-3d': 'Уровень 3D',
  'beta-testing': 'Участвует в бета тестировании',
  'dataset-voter': 'Обучение нейронной сети',
  'can-get-plans-to-work': 'Возможность брать планировки',
  'single-floor-cutting': 'Одноэтажная обработка',
  'multiple-floor-cutting': 'Одноэтажная обработка',
  'selected-lossless-format': 'Формат изображения глубины',
  'img-has-been-deleted': 'Изображение удалено!',
};
